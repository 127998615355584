<template>
  <!-- <test  v-model="article.content" ></test> -->
  <rich-text-editor v-model="article.content"></rich-text-editor>
</template>
<script>
import RichTextEditor from './richTextEditor.vue';
export default {
  components: {
    RichTextEditor
  },
  data() {
    return {
      article: {
        content: "",
      },
    };
  },
};
</script>
<style></style>
